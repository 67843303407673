import { useForm } from 'react-hook-form';
import { useRef, useState } from 'react';
import ApiService from "../../../services/api.service";
import HideOpenedSelect from "../../helper/HideOpenedSelect";

const ChangeShippingForm = ({ data, setData, setShowShippingForm }) => {

    const { register, handleSubmit, formState: { errors }, setValue, clearErrors, setError } = useForm();

    const emptyErrorMessage = 'Bitte fülle dieses Feld aus';
    const postalErrorMessage = 'Fehlerhafte Postleitzahl';

    const [error, setApiError] = useState(null);
    if (error) throw error;

    const [dhlError, setDhlError] = useState(false);
    const activeDhl = !dhlError ? true : false;

    const [dhlShippingResponse, setDhlShippingResponse] = useState();
    const [dhlShippingSelection, setDhlShippingSelection] = useState();
    const [dhlShippingOptionsSelect, setDhlShippingOptionsSelect] = useState('hidden');

    const shippingRef = useRef(null);
    HideOpenedSelect(shippingRef, dhlShippingOptionsSelect, setDhlShippingOptionsSelect);


    const dhlOnChangeField = (e) => {
        let combined = e.target.value;
        if (combined !== '') {
            setDhlShippingSelection();

            const API_URL = global.config.express.url + '/dhldata';
            const API_DATA = {
                'combined': combined
            }
            ApiService.getData(API_URL, API_DATA).then((response) => {
                if (typeof (response.apierror) !== 'undefined') {
                    setDhlError(true);
                    setValue('country', 'Deutschland');
                }
                else {
                    setDhlShippingResponse(response);
                    setDhlShippingOptionsSelect('visible');
                }
            }).catch((error) => { setApiError(error) });
        }
        else {
            setDhlShippingResponse();
            setDhlShippingSelection();
            setDhlShippingOptionsSelect('hidden');
        }
    }

    const dhlChangeFieldValue = (e, data) => {
        setValue('shippingaddress', e.target.innerText);
        setDhlShippingSelection({
            'street': data.street + ' ' + data.houseNumber,
            'postal': data.postalCode,
            'city': data.city
        })
        setDhlShippingOptionsSelect('hidden');
        clearErrors('shippingaddress');

    }

    const jumpToSelection = (e) => {
        if (e.keyCode === 40) {
            e.target.nextSibling.firstChild.focus();
        }
    }

    const jumpInsideSelection = (e) => {
        if (e.keyCode === 40) {
            e.target.nextSibling.focus();
        }
        if (e.keyCode === 38) {
            e.target.previousSibling.focus();
        }
    }

    let shippingAdressSelection;
    if (dhlShippingResponse) {
        shippingAdressSelection = dhlShippingResponse.map((data) => (
            <button type="button" onClick={(e) => dhlChangeFieldValue(e, data, 'shipping')} onMouseEnter={() => document.activeElement.blur()} onKeyDown={(e) => jumpInsideSelection(e)} key={data.uuid}>{data.street + ' ' + data.houseNumber + ', ' + data.postalCode + ' ' + data.city}</button>
        ));
    }


    const onSubmit = (values) => {

        let shippingdata;
        if (!activeDhl) {
            shippingdata = {
                'first-name': values.firstname,
                'last-name': values.lastname,
                'street': values.street,
                'country-code': 'DE',
                'postal-code': values.postal,
                'city': values.city,
                'email': data.email
            };
        }
        else {
            if (typeof (dhlShippingSelection) !== 'undefined') {
                shippingdata = {
                    'first-name': values.firstname,
                    'last-name': values.lastname,
                    'street': dhlShippingSelection.street,
                    'country-code': 'DE',
                    'postal-code': dhlShippingSelection.postal,
                    'city': dhlShippingSelection.city,
                    'email': data.email
                }
            }
            else {
                setValue('shippingaddress', '');
                setError('shippingaddress', { type: 'custom', message: 'Bitte wähle eine gültige Adresse' });
                setDhlShippingSelection();
                setDhlShippingOptionsSelect('hidden');
                return false;
            }
        }

        if (!errors.shippingaddress) {
            setData(shippingdata);
            setShowShippingForm(false);
        }
    }

    return (
        <>
            <div className="edit-adressdata">
                <h2>Lieferadresse:</h2>
                <form onSubmit={handleSubmit(onSubmit)} className="form-add">
                    <div className="third-left standard mandatory">
                        <label>Vorname<span className="mandatory">*</span></label>
                        <div className="formfieldholder">
                            <input
                                type="text"
                                defaultValue={data['first-name']}
                                {...register('firstname', {
                                    required: emptyErrorMessage
                                })}
                            />
                            {errors.firstname && <div className="error">{errors.firstname.message}</div>}
                        </div>
                    </div>
                    <div className="third-middle standard mandatory">
                        <label>Nachname<span className="mandatory">*</span></label>
                        <div className="formfieldholder">
                            <input
                                type="text"
                                defaultValue={data['last-name']}
                                {...register('lastname', {
                                    required: emptyErrorMessage
                                })}
                            />
                            {errors.lastname && <div className="error">{errors.lastname.message}</div>}
                        </div>
                    </div>
                    {activeDhl
                        ?
                        <>
                            <div className="third-right standard mandatory">
                                <label>Anschrift<span className="mandatory">*</span></label>
                                <div className="formfieldholder overflow input-holder" ref={shippingRef}>
                                    <input
                                        type="text"
                                        {...register('shippingaddress', {
                                            required: emptyErrorMessage
                                        })}
                                        onChange={(e) => dhlOnChangeField(e)}
                                        onKeyDown={(e) => jumpToSelection(e)}
                                    />
                                    <div className={dhlShippingOptionsSelect + " select_options"}>
                                        {shippingAdressSelection}
                                    </div>
                                    {errors.shippingaddress && <div className="error">{errors.shippingaddress.message}</div>}
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="third-left standard mandatory">
                                <label>Straße / Hausnummer<span className="mandatory">*</span></label>
                                <div className="formfieldholder">
                                    <input
                                        type="text"
                                        defaultValue={data['street']}
                                        {...register('street', {
                                            required: emptyErrorMessage
                                        })}
                                    />
                                    {errors.street && <div className="error">{errors.street.message}</div>}
                                </div>
                            </div>
                            <div className="third-middle plz mandatory">
                                <label>PLZ<span className="mandatory">*</span></label>
                                <div className="formfieldholder">
                                    <input
                                        type="text"
                                        defaultValue={data['postal-code']}
                                        maxLength={5}
                                        {...register('postal', {
                                            required: emptyErrorMessage,
                                            pattern: {
                                                value: /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/,
                                                message: postalErrorMessage
                                            }
                                        })}
                                    />
                                    {errors.postal && <div className="error">{errors.postal.message}</div>}
                                </div>
                            </div>
                            <div className="third-right standard mandatory">
                                <label>Ort<span className="mandatory">*</span></label>
                                <div className="formfieldholder">
                                    <input
                                        type="text"
                                        defaultValue={data['city']}
                                        {...register('city', {
                                            required: emptyErrorMessage
                                        })}
                                    />
                                    {errors.city && <div className="error">{errors.city.message}</div>}
                                </div>
                            </div>
                        </>
                    }

                    <div className="submit_container">
                        <div className="formfieldholder">
                            <button type="submit" className="submit">Speichern</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )

}

export default ChangeShippingForm;